import { FunctionComponent, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import useSWR from 'swr';
import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { BasicSelectInput } from '../FormFields/BasicSelectInput';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { useAppContext } from '../../../context/appContext';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import { Tag } from '../../Common/Tag';
import { LabeledInputWrapper } from '../FormFields/LabeledInputWrapper';
import { classNames } from '../../../utilities/classNames';
import { ListSelect } from '../FormFields/ListSelect/ListSelect';
import { getColorForGroup } from './studentGroupHelpers';
import { StudentGroup } from '@tr-types/backend-types';

interface Props {
  defaultGroups?: StudentGroup[];
  onGroupsChanged: (groups: StudentGroup[]) => void;
  small?: boolean;
  required?: boolean;
  label: string;
}

export const StudentGroupsInput: FunctionComponent<Props> = ({
  defaultGroups,
  onGroupsChanged,
  required,
  small,
  label,
}) => {
  const { organizationId } = useAppContext();

  const { t } = useTranslation('translation', {
    keyPrefix: 'organization.studentGroups',
  });

  const [selectedGroupsState, setSelectedGroupsState] = useState(defaultGroups);

  const { data: studentGroups } = useSWR<StudentGroup[] | null>(
    () =>
      organizationId &&
      HttpEndpoints.StudentGroupEndpoints.getStudentGroupsForOrganization(
        organizationId,
      ),
    authenticatedFetcher,
    { fallbackData: [] },
  );

  const ref = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    if (
      required &&
      (!selectedGroupsState || selectedGroupsState.length === 0) &&
      studentGroups.length > 0
    ) {
      ref.current.setCustomValidity(t('errorRequired'));
    } else {
      ref.current.setCustomValidity('');
    }
  }, [selectedGroupsState, required, studentGroups, t]);

  if (studentGroups.length === 0) return null;

  return (
    <ListSelect<StudentGroup>
      defaultItems={defaultGroups}
      onSelectedValuesChange={(groups) => {
        onGroupsChanged(groups);
        setSelectedGroupsState(groups);
      }}
    >
      {(selectedValues, addItem, removeItem) => (
        <LabeledInputWrapper label={label} small={small} required={required}>
          <div className="text-sm font-normal">
            <div className="flex mb-5">
              <BasicSelectInput
                small
                inputRef={ref}
                options={
                  studentGroups?.filter(
                    (g) =>
                      !selectedValues.map((group) => group.id).includes(g.id),
                  ) ?? []
                }
                placeholder={t('addAGroup')}
                onChange={(e, setSelectedValue) => {
                  const selectedGroupId = e.target.value;
                  const item = studentGroups?.find(
                    (g) => g.id === selectedGroupId,
                  );
                  if (item) addItem(item);
                  setSelectedValue('');
                }}
                bindLabel={(g) => g.title}
                bindValue={(g) => g.id}
              />
            </div>
            {selectedValues.map((g, i) => {
              const color = getColorForGroup(g);
              return (
                <div className="inline-flex" key={g.id}>
                  <Tag color={color}>
                    {g.title}
                    <MinusCircleIcon
                      className={classNames(
                        'w-5 h-5 inline cursor-pointer ml-1',
                        `text-${color}-600 hover:text-${color}-800`,
                      )}
                      onClick={() => {
                        removeItem(i);
                      }}
                    />
                  </Tag>
                </div>
              );
            })}
          </div>
        </LabeledInputWrapper>
      )}
    </ListSelect>
  );
};

import { ColorDot } from '../../components/Common/ColorDot';
import { Color } from '../../typings/backend-types';
import { withColorLabel } from './withColorLabel';

export function formatNameWithLabel(
  o?: {
    firstName?: string | null;
    lastName?: string | null;
    label?: Color | null;
  } | null,
): JSX.Element | null {
  if (!o) return null;
  return withColorLabel(
    [o.lastName, o.firstName].filter(Boolean).join(', '),
    o.label,
  );
}

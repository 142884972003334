import { FunctionComponent } from 'react';
import { Color } from '../../typings/backend-types';
import { classNames } from '../../utilities/classNames';

interface Props {
  color: Color | null | undefined;
  large?: boolean;
}

export const colorDotVariant = 400;

export const ColorDot: FunctionComponent<Props> = ({ color, large }) => {
  if (!color) return null;

  return (
    <div
      className={classNames(
        'rounded-full',
        `bg-${color}-${colorDotVariant}`,
        large ? 'w-5 h-5' : 'w-4 h-4',
      )}
    />
  );
};

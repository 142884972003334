import { FunctionComponent, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import Link from 'next/link';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { BasicTextInput, dateInputFormat } from '../FormFields/BasicTextInput';
import { BasicSelectInput } from '../FormFields/BasicSelectInput';
import { BasicAddressInput } from '../FormFields/BasicAddressInput';
import { BasicTextAreaInput } from '../FormFields/BasicTextAreaInput';
import containsAllNull, {
  allPropertiesEmpty,
} from '../../../utilities/containsAllNull';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { useAppContext } from '../../../context/appContext';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import { useNotificationContext } from '../../../context/notificationContext';
import { formatName } from '../../../utilities/formatName';
import { useErrorPopupContext } from '../../../context/errorPopupContext';
import { ColorPickerInput } from '../FormFields/ColorPickerInput';
import { colorDotVariant } from '../../Common/ColorDot';
import { formatNameWithLabel } from '../../../utilities/students/formatNameWithLabel';
import { StudentGroupsInput } from './StudentGroupsInput';
import { FormSection } from '@components/Forms/FormSection';
import {
  Student,
  StudentGroup,
  Address,
  FullAddress,
  BasicUserInfo,
  Color,
} from '@tr-types/backend-types';

interface Props {
  canSelectInstructor: boolean;
  small?: boolean;
  defaultValues?: Partial<Student>;
  onGroupsChanged?: (groups: StudentGroup[]) => void;
}

export interface StudentFormElements {
  first: HTMLInputElement;
  last: HTMLInputElement;
  email: HTMLInputElement;
  billingEmail?: HTMLInputElement;
  phone: HTMLInputElement;
  secondaryPhone?: HTMLInputElement;
  dateOfBirth?: HTMLInputElement;
  street?: HTMLInputElement;
  number?: HTMLInputElement;
  zip?: HTMLInputElement;
  city?: HTMLInputElement;
  country?: HTMLInputElement;
  addressee2?: HTMLInputElement;
  street2?: HTMLInputElement;
  number2?: HTMLInputElement;
  zip2?: HTMLInputElement;
  city2?: HTMLInputElement;
  country2?: HTMLInputElement;
  instructor?: HTMLInputElement;
  notes?: HTMLTextAreaElement;
  occupation?: HTMLInputElement;
  label?: HTMLInputElement;
  permitExpirationDate?: HTMLInputElement;
}

export interface StudentFormValues {
  firstName: string;
  lastName: string;

  /** @format date-time */
  dateOfBirth?: string;
  phone: string;
  secondaryPhone?: string;
  email?: string;
  billingEmail?: string;
  notes?: string;
  primaryAddress?: Address;
  billingAddress?: FullAddress;
  archived?: boolean;
  occupation?: string;
  auth0Id?: string | null;
  label?: Color | null;
  permitExpirationDate?: string | null;
}

export function getStudentFormValues(
  target: StudentFormElements,
): StudentFormValues {
  const primaryAddress = {
    street: target.street?.value,
    number: target.number?.value,
    zip: target.zip?.value,
    city: target.city?.value,
    country: target.country?.value,
  };
  const billingAddress = {
    addressee: target.addressee2?.value,
    street: target.street2?.value,
    number: target.number2?.value,
    zip: target.zip2?.value,
    city: target.city2?.value,
    country: target.country2?.value,
  };
  return {
    firstName: target.first.value,
    lastName: target.last.value,
    email: target.email.value,
    billingEmail: target.billingEmail?.value || null,
    phone: target.phone.value,
    secondaryPhone: target.secondaryPhone?.value || null,
    dateOfBirth: target.dateOfBirth?.value
      ? dayjs.utc(target.dateOfBirth.value, dateInputFormat).toISOString()
      : null,
    primaryAddress: !containsAllNull(primaryAddress) ? primaryAddress : null,
    billingAddress: !containsAllNull(billingAddress) ? billingAddress : null,
    notes: target.notes?.value || null,
    occupation: target.occupation?.value || null,
    label: (target.label?.value as Color) || null,
    permitExpirationDate: target.permitExpirationDate?.value || null,
  };
}

export async function checkDuplicateStudent(
  form: StudentFormElements,
  organizationId: string,
): Promise<Student[] | null> {
  const formValues = getStudentFormValues(form);
  const criteria = [
    formValues.firstName,
    formValues.lastName,
    form.phone.validity.valid && formValues.phone,
    form.email.validity.valid && formValues.email,
  ].filter(Boolean);

  if (criteria.length < 2) return null;
  const matches: Student[] | null = await authenticatedFetcher(
    HttpEndpoints.StudentEndpoints.getStudentsForOrganization(organizationId, {
      query: criteria.join(' '),
      includeBalance: false,
    }),
  );
  return matches && matches?.length > 0 ? matches : null;
}

export const StudentFormFields: FunctionComponent<Props> = ({
  canSelectInstructor,
  small,
  defaultValues,
  onGroupsChanged,
}) => {
  const { organizationId } = useAppContext();
  const { setNotification } = useNotificationContext();
  const { setErrorMessage } = useErrorPopupContext();
  const [billingAddressGiven, setBillingAddressGiven] = useState(
    !allPropertiesEmpty(defaultValues?.billingAddress),
  );

  const { data: instructors } = useSWR<BasicUserInfo[]>(
    () => HttpEndpoints.UserEndpoints.basicUserInfosForOrg(organizationId),
    authenticatedFetcher,
    {
      fallbackData: [],
      onError: () => setErrorMessage(t('errorFetchInstructorsGeneric')),
    },
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'students.studentDetail',
  });

  async function showDuplicateAlert(form: StudentFormElements) {
    if (defaultValues?.id) return;

    const matches = await checkDuplicateStudent(form, organizationId);

    if (matches && matches?.length > 0) {
      setNotification({
        title:
          matches.length === 1
            ? t('matchingStudent')
            : t('multipleMatchingStudents'),
        message: getStudentLinkList(matches),
        icon: <ExclamationCircleIcon className="w-6 h-6 text-orange-600" />,
      });
      return true;
    } else {
      setNotification(null);
      return false;
    }
  }

  function getStudentLinkList(students: Student[]): JSX.Element {
    return (
      <>
        {students.map((s) => (
          <div key={s.id}>
            <Link href={'/students/' + encodeURIComponent(s.id)} passHref>
              <a target="_blank" className="hover:text-accent-600">
                {formatNameWithLabel(s)}
              </a>
            </Link>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <FormSection title={t('personalInformation')} hidden={small}>
        <BasicTextInput
          label={t('firstName')}
          required
          small={small}
          value={defaultValues?.firstName}
          formName="first"
          autoComplete="given-name"
          onBlur={(e) =>
            showDuplicateAlert(e.target.form as unknown as StudentFormElements)
          }
          type="text"
        />
        <BasicTextInput
          label={t('lastName')}
          required
          small={small}
          onBlur={(e) =>
            showDuplicateAlert(e.target.form as unknown as StudentFormElements)
          }
          value={defaultValues?.lastName}
          formName="last"
          autoComplete="family-name"
          type="text"
        />
        <BasicTextInput
          label={t('dateOfBirth')}
          type="date"
          formName="dateOfBirth"
          small={small}
          value={
            defaultValues?.dateOfBirth &&
            dayjs(defaultValues?.dateOfBirth).format(dateInputFormat)
          }
        />
        {!small && (
          <BasicTextInput
            label={t('occupation')}
            small={small}
            value={defaultValues?.occupation}
            formName="occupation"
            type="text"
          />
        )}
      </FormSection>
      <FormSection title={t('contactInfo')} hidden={small}>
        <BasicTextInput
          label={t('email')}
          required
          small={small}
          value={defaultValues?.email}
          formName="email"
          onBlur={(e) =>
            showDuplicateAlert(e.target.form as unknown as StudentFormElements)
          }
          autoComplete="email"
          type="email"
        />
        {!small && (
          <BasicTextInput
            label={t('billingEmail')}
            small={small}
            value={defaultValues?.billingEmail}
            formName="billingEmail"
            autoComplete="email"
            required={billingAddressGiven}
            type="email"
          />
        )}
        <BasicTextInput
          label={t('phoneNumber')}
          required
          small={small}
          value={defaultValues?.phone}
          onBlur={(e) =>
            showDuplicateAlert(e.target.form as unknown as StudentFormElements)
          }
          formName="phone"
          autoComplete="telephone"
          type="tel"
        />
        {!small && (
          <BasicTextInput
            label={t('secondaryPhoneNumber')}
            small={small}
            value={defaultValues?.secondaryPhone}
            className={small ? 'hidden' : undefined}
            formName="secondaryPhone"
            autoComplete="telephone"
            type="tel"
          />
        )}
        {!small && (
          <BasicAddressInput
            label={t('address')}
            small={small}
            value={defaultValues?.primaryAddress}
          />
        )}
        {!small && (
          <BasicAddressInput
            label={t('billingAddress')}
            small={small}
            className={small ? 'hidden' : undefined}
            formName={{
              street: 'street2',
              number: 'number2',
              zip: 'zip2',
              city: 'city2',
              country: 'country2',
              addressee: 'addressee2',
            }}
            showAddressee
            onChange={(address) =>
              setBillingAddressGiven(!allPropertiesEmpty(address))
            }
            value={defaultValues?.billingAddress}
          />
        )}
      </FormSection>

      <FormSection title={t('internalInformation')} hidden={small}>
        {canSelectInstructor && (
          <BasicSelectInput<BasicUserInfo>
            formName="instructor"
            label={t('instructor')}
            hideNoneSelect
            small={small}
            value={defaultValues?.instructor?.id}
            options={instructors}
            bindLabel={formatName}
            bindValue={(i) => i.id}
          />
        )}
        {!small && (
          <BasicTextAreaInput
            label={t('notes')}
            formName="notes"
            small={small}
            value={defaultValues?.notes ?? ''}
          />
        )}
        {!small && (
          <BasicTextInput
            label={t('permitExpirationDate')}
            formName="permitExpirationDate"
            type="date"
            value={
              defaultValues?.permitExpirationDate &&
              dayjs(defaultValues?.permitExpirationDate).format(dateInputFormat)
            }
          />
        )}
        {!small && (
          <ColorPickerInput
            label={t('label')}
            formName="label"
            variant={colorDotVariant}
            value={defaultValues?.label}
          />
        )}
        {onGroupsChanged && (
          <StudentGroupsInput
            defaultGroups={defaultValues?.groups}
            required
            small={small}
            onGroupsChanged={onGroupsChanged}
            label={t('groups')}
          />
        )}
      </FormSection>
    </>
  );
};

import { useEffect, useState } from 'react';

interface Props<T> {
  defaultItems?: T[];
  onSelectedValuesChange?: (values: T[]) => void;
  children: (
    selectedValues: T[],
    onAdd: (value: T) => void,
    onDelete: (index: number) => void,
  ) => JSX.Element | JSX.Element[];
}

export function ListSelect<T>({
  defaultItems,
  children,
  onSelectedValuesChange,
}: Props<T>): JSX.Element {
  useEffect(() => {
    setSelectedItems(defaultItems ?? []);
  }, [defaultItems]);

  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  function deleteItem(index: number) {
    const newItems = [...selectedItems.filter((_, i) => i !== index)];
    setSelectedItems(newItems);
    onSelectedValuesChange?.(newItems);
  }

  function addItem(item: T) {
    if (!item) return;
    const newItems = [...selectedItems, item];
    setSelectedItems(newItems);
    onSelectedValuesChange?.(newItems);
  }

  return <>{children(selectedItems, addItem, deleteItem)}</>;
}

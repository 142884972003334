import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions, {
  Breakpoints,
} from '../../../hooks/useWindowDimensions';
import { BasicTextInput } from './BasicTextInput';
import { LabeledInputWrapper } from './LabeledInputWrapper';
import { classNames } from 'utilities/classNames';
import { FullAddress } from '@tr-types/backend-types';

interface Props {
  label: string;
  formName?: FullAddress;
  required?: boolean;
  value?: FullAddress;
  small?: boolean;
  tiny?: boolean;
  className?: string;
  helpText?: string;
  showAddressee?: boolean;
  onChange?: (value: FullAddress) => void;
  gapX?: number;
  gapY?: number;
}

export const BasicAddressInput: FunctionComponent<Props> = ({
  label,
  formName = {
    street: 'street',
    number: 'number',
    zip: 'zip',
    city: 'city',
    country: 'country',
    addressee: 'addressee',
  },
  required,
  value,
  small,
  tiny,
  helpText,
  onChange,
  className,
  showAddressee,
  gapX = 3,
  gapY = 3,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'formFields.addressInput',
  });

  const { width } = useWindowDimensions();
  const [currentValue, setCurrentValue] = useState<FullAddress>(value ?? {});

  const handleChange = (field: keyof FullAddress, newValue: string) => {
    const updatedValue = {
      ...currentValue,
      [field]: newValue,
    };
    setCurrentValue(updatedValue);
    if (onChange) {
      onChange(updatedValue);
    }
  };

  useEffect(() => {
    if (value) setCurrentValue(value ?? {});
  }, [value]);

  return (
    <LabeledInputWrapper
      label={label}
      className={className}
      small={small}
      required={required}
    >
      <div className={`grid grid-cols-5 gap-x-${gapX} gap-y-${gapY}`}>
        {showAddressee && (
          <BasicTextInput
            formName={formName.addressee}
            label={t('addressee')}
            type="text"
            value={currentValue.addressee}
            className="col-span-5"
            required={required}
            onChange={(e) => handleChange('addressee', e.target.value)}
            small
            tiny={tiny}
          />
        )}
        <BasicTextInput
          formName={formName.street}
          label={t('street')}
          type="text"
          value={currentValue.street}
          className="col-span-4"
          onChange={(e) => handleChange('street', e.target.value)}
          required={required}
          small
          tiny={tiny}
        />
        <BasicTextInput
          formName={formName.number}
          label={
            width < Breakpoints.MD || small ? t('numberShort') : t('number')
          }
          type="text"
          onChange={(e) => handleChange('number', e.target.value)}
          value={currentValue.number}
          required={required}
          small
          tiny={tiny}
        />
        <BasicTextInput
          formName={formName.zip}
          label={t('zipCode')}
          type="text"
          value={currentValue.zip}
          onChange={(e) => handleChange('zip', e.target.value)}
          className={classNames(!small && 'sm:col-span-1', 'col-span-2')}
          required={required}
          small
          tiny={tiny}
        />
        <BasicTextInput
          formName={formName.city}
          label={t('city')}
          type="text"
          value={currentValue.city}
          onChange={(e) => handleChange('city', e.target.value)}
          className={classNames('col-span-3', !small && 'sm:col-span-2')}
          required={required}
          customInputValidation={(city) => {
            if (city.match(/\d/)) {
              return t('cityCannotContainNumbers');
            }
            return null;
          }}
          tiny={tiny}
          small
        />
        <BasicTextInput
          formName={formName.country}
          label={t('country')}
          value={currentValue.country}
          type="text"
          onChange={(e) => handleChange('country', e.target.value)}
          className={classNames('col-span-5', !small && 'sm:col-span-2')}
          required={required}
          placeholder={t('countryPlaceholder')}
          customInputValidation={(country) => {
            if (country.match(/\d/)) {
              return t('countryCannotContainNumbers');
            }
            return null;
          }}
          tiny={tiny}
          small
        />
      </div>
      {helpText && (
        <p className="mt-2 text-sm text-gray-500 font-normal">{helpText}</p>
      )}
    </LabeledInputWrapper>
  );
};
